<template>

<Map ref="metric" :path="path" >
</Map>
<hr>
 <div style="height:200px;">
    
       <MonacoEditor 
        class="editor" 
        ref="editor"
        :options="option"
        v-model="text"
        language="sql" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
    </div>
<button class="btn btn-dark" v-on:click="updateSql" > SQLを実行 </button>
</template>

<script>

import MonacoEditor from 'monaco-editor-vue3'

import Map from "../../components/chart/Map.vue";

export default {
  name: 'MapChart',
  components:  { Map,MonacoEditor } ,
  data(){  return {
        path : "" ,
        text : "" , 
        option : {"value":this.text}
        };
    },
  async created( ) {
      

      this.path = window.location.href;   
      const param = new URLSearchParams(window.location.search);
       const q = param.get('q');
       if(q){
       this.text = q ;
       this.option.value = q;
       }
       
               },
    methods: {
        
        editorDidChange(text){     
            this.text  = text;  
        }, 
       async updateSql (){
        
        const q = this.text.replaceAll('+','%2B').replace( /\r?\n/g , '');
        
        const path = window.location.href + "&q="+q ;
         
                 if ( window.parent !==  window && !window.isCrossOrigin) { 
          
               window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?chartSql="+ q.replaceAll ( '+','%2B' )   ) ;  

           } 


           this.$refs.metric.showData(window.parent.Sql,path );

        }
    }
}

</script>

<style>
main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}</style>